import React, {useEffect, useState} from 'react';
import Grid from "@material-ui/core/Grid";
import {Card, Typography} from "@material-ui/core";
import {withRouter} from "react-router";
import {useIntl} from "react-intl";
import makeStyles from "@material-ui/core/styles/makeStyles";
import {Theme} from "@material-ui/core/styles";
import ExpansionPanelSummary from "@material-ui/core/ExpansionPanelSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpansionPanelDetails from "@material-ui/core/ExpansionPanelDetails";
import ExpansionPanel from "@material-ui/core/ExpansionPanel";
import PhoenixApiManager from "../../../../../Api/PhoenixApiManager";
import {PhoenixFaultTreeMxGraph} from "../../PhoenixTreeMxGraphView";
import ActionButtons from "../../../../primitives/Button/ActionButtons";
import SelectedPIFs from './SelectedPIFs';
import AvatarText from "../../../../primitives/Icons/AvatarText";

interface Props {
  basePath: string;
  detail: any;
  cfmProgress: any;
  pifProgress: any;
  history: any;
  match: any;
  onResponseUpdated: () => void;
  updateScore: ()=> void,
}

const useStyles = makeStyles((theme: Theme) => ({
  alignCenter: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  },
  cardContentRoot: {
    minWidth: '100%'
  },
  expansionPanelExpanded: {
    minHeight: '100%',
    margin: '0 !important'
  },
  expansionPanelSummaryRoot: {
    minHeight: '100px !important'
  },
  expansionPanelSummaryExpanded: {
    margin: `0 !important`,
  },
  expansionPanelSummaryContent: {
    margin: '0 !important'
  },
  pifRow: {
    margin: `${theme.spacing(0.5)}px 0`
  }
}));

const SelectedCFMs = (props: Props) => {
  const {getProgress, basePath, detail, pifProgress, match, onResponseUpdated, cfId } = props;
  const [cfmGraphs, setCFMGraphs] = useState(null);
  const cfms = Object.keys(detail.cfms);


  useEffect(() => {
    cfms.map((cfmKey: string) => {
      const cfmTreeId = detail.cfms[cfmKey].fault_tree_id;


      PhoenixApiManager.getPhoenixTreeWithMetadata(cfmTreeId)
        .then((response: any) => response.json())
        .then((data) => {
          setCFMGraphs((prevState: any) => {
          return {
          ...prevState,
          [cfmKey]: new PhoenixFaultTreeMxGraph(data.tree_data)
        }
      })
    });
    })
  },[]);

  const classes = useStyles();
  const intl = useIntl();

  let content: any;
  if (cfms.length) {
    content = cfms.map((key: string) => {
      const pifs = detail.cfms[key].pifs;

      const editShouldBeDisabled = () => {
        const cfmKeys = Object.keys(detail.cfms);
        const cfmIndex = cfmKeys.findIndex((cfmKey: string) => key === cfmKey);
        // First CFM should never be disabled
        if (cfmIndex === 0) return false;

        // Check if prevCFM's PIFs are not answered

        const prevCFMPIFs = Object.values(detail.cfms[cfmKeys[cfmIndex - 1]].pifs);
        return prevCFMPIFs.length === 0 || prevCFMPIFs.some((pif: any) => Object.values(pif.questions).length === 0);
      };



      const cfmHeader = () => (
        <Grid container>
          <Grid item xs={8} className={classes.alignCenter}>
            <AvatarText text={'CFM'} />
            <Typography variant="h6">{detail.cfms[key].label.name}</Typography>
          </Grid>
          <Grid item xs={4} className={classes.alignCenter}>
            {
              (
                <Grid container justify={"flex-end"}>
                  <ActionButtons
                    // graphView={{
                    //   graph: cfmGraphs[key],
                    //   toolTipTitle: 'View CFM'
                    // }}
                    editLink={{
                      url: `${basePath}cfm/${key}`,
                      disabled: false
                    }}
                    quantifyLink={{
                      url: `${basePath}cfm/${key}/quantify/`,
                      disabled: false
                    }}
                  />
                </Grid>
              )
            }
          </Grid>
        </Grid>
      );

      return (
        <Grid item xs={12}>
          <ExpansionPanel
            component={Card}
            defaultExpanded
            classes={{ expanded: classes.expansionPanelExpanded }}
          >
            <ExpansionPanelSummary
              classes={{
                root: classes.expansionPanelSummaryRoot,
                expanded: classes.expansionPanelSummaryExpanded,
                content: classes.expansionPanelSummaryContent
              }}
              expandIcon={<ExpandMoreIcon />}
            >
              {cfmHeader()}
            </ExpansionPanelSummary>
            <ExpansionPanelDetails>
              <Grid container>
                <SelectedPIFs
                  pifs={pifs}
                  cfId={cfId}
                  cfmKey={key}
                  detail={detail}
                  pifProgress={pifProgress}
                  match={match}
                  onResponseUpdated={onResponseUpdated}
                  updateScore={props.updateScore}
                  getProgress={getProgress}

                />
              </Grid>
            </ExpansionPanelDetails>
          </ExpansionPanel>
        </Grid>
      );
    });
  } else {
    content = (
      <Grid item xs={12}>
        <Typography variant="caption">{intl.formatMessage({ id: 'noSelection.cfms' })}</Typography>
      </Grid>
    );
  }

  return (
    <ExpansionPanel
      component={Card}
      defaultExpanded
      classes={{ expanded: classes.expansionPanelExpanded }}
    >
      <ExpansionPanelSummary
        expandIcon={<ExpandMoreIcon />}
        classes={{
          expanded: classes.expansionPanelSummaryExpanded,
          content: classes.expansionPanelSummaryContent
        }}
      >
        <Typography variant="h5">{intl.formatMessage({ id: 'analysis.cfm' })}</Typography>
      </ExpansionPanelSummary>
      <ExpansionPanelDetails>
        <Grid container spacing={2} alignItems="stretch">
          {content}
        </Grid>
      </ExpansionPanelDetails>
    </ExpansionPanel>
  );
};

export default withRouter(SelectedCFMs);
