import React, { useEffect, useState } from "react";
import {
  Grid,
  Card,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Modal,
  Box,
} from "@material-ui/core";
import PIFEntry from "../PIF/PIFEntry/PIFEntry";
import { useIntl } from "react-intl";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import PhoenixApiManager from "../../../../../Api/PhoenixApiManager";

interface Props {
  detail: any;
  pifs: any;
  cfmKey: string;
  pifProgress: any[];
  match: any;
  onResponseUpdated: () => void;
  updateScore: () => void;
  payload?: any[];

}

const SelectedPIFs = (props: Props) => {
  const {
    getProgress,
    detail,
    pifs = {},
    cfmKey,
    pifProgress = [],
    cfId,
    match: {
      params: { id },
    },
    onResponseUpdated,
    payload = [],
  } = props;

  const intl = useIntl();
  const [selectedModel, setSelectedModel] = useState<string>("");
  const [selectedCFS, setSelectedCFS] = useState<string>("");
  const [selectedBP, setSelectedBP] = useState<string>("");
  const [selectedCFM, setSelectedCFM] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [crtModelId, setCrtModelId] = useState<string>("");
  const [cfmList, setCfmList] = useState([])

  const cfmLabel = detail.cfms[cfmKey].label
    ? detail.cfms[cfmKey].label.name
    : detail.cfms[cfmKey];

  const modalStyle: React.CSSProperties = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    backgroundColor: "white",
    boxShadow: "0px 4px 10px rgba(0,0,0,0.2)",
    padding: "20px",
    borderRadius: "8px",
  };

  useEffect(()=>{
    PhoenixApiManager.getCFMListForCopy(id).then(response => setCfmList(response.data))
  }, [])

  const handleModelChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedModel(event.target.value as string);
    setSelectedCFS("");
    setSelectedBP("");
    setSelectedCFM("");
    setCrtModelId("");
  };

  const handleCFSChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    const selectedCFSValue = event.target.value as string;
    setSelectedCFS(selectedCFSValue);

    const selectedCFSObject = cfmList
      .find((model: any) => model?.id?.toString() === selectedModel)
      ?.cfs.find((cf: any) => cf.title === selectedCFSValue);

    if (selectedCFSObject && selectedCFSObject.crt_model_id) {
      setCrtModelId(selectedCFSObject.crt_model_id);
    } else {
      setCrtModelId("");
    }

    setSelectedBP("");
    setSelectedCFM("");
  };

  const handleBPChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedBP(event.target.value as string);
    setSelectedCFM("");
  };

  const handleCFMChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedCFM(event.target.value as string);
  };

  const toggleModal = () => {
    setModalOpen((prev) => !prev);
  };

  const handleCopyCFM = () => {
    if (!selectedModel || !selectedCFS || !selectedBP || !selectedCFM) {
      console.error("Please select all required fields.");
      return;
    }

    const data = {
      crt_model_id: crtModelId,
      target_hfe: selectedBP,
      target_cfm: selectedCFM,
      source_hfe: detail?.name,
      source_cfm: cfmKey,
    };

    PhoenixApiManager.copyCFMWithCfId(cfId, data)
      .then((response) => {
        console.log("CFM copied successfully:", response.data);
        onResponseUpdated();
        toggleModal();
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error copying CFM:", error);
      });
  };

  const selectedModelData = cfmList.find((model: any) => model?.id?.toString() === selectedModel);
  const cfs = selectedModelData ? selectedModelData.cfs : [];

  const bps = selectedModelData && selectedCFS
    ? selectedModelData.cfs
        .filter((cf: any) => cf.title === selectedCFS)
        .flatMap((cf: any) => Object.keys(cf.bps || {}))
    : [];

  const cfms = selectedModelData && selectedCFS
    ? selectedModelData.cfs
        .filter((cf: any) => cf.title === selectedCFS)
        .flatMap((cf: any) =>
          Object.entries(cf.bps || {})
            .filter(([bpKey]) => bpKey === selectedBP)
            .flatMap(([_, cfmData]) => Object.keys(cfmData))
        )
    : [];

  return (
    <>
      <Grid xs={12}>
        <Typography>{intl.formatMessage({ id: "analysis.pif" })}</Typography>
      </Grid>
      {Object.keys(pifs).map((pifKey: any) => {
        const pif = pifs[pifKey];
        const progress = pifProgress.find((item: any) => {
          return item.name === pifKey && item.cfm.includes(cfmLabel);
        });

        const getScore = () => {
          if (pif && progress.total === progress.completed) {
            return pif.score;
          }
          return null;
        };
        return (
          <Grid item xs={12} lg={6} component={Card}>
            <PIFEntry
              key={pifKey}
              pifName={pifKey}
              pifScore={getScore()}
              pifOverride={pif.override}
              pifExpression={pif.expression}
              cfmKey={cfmKey}
              cfmLabel={cfmLabel}
              hfeKey={detail.name}
              modelId={id}
              cfid={cfId}
              progress={{
                completed: progress.completed,
                total: progress.total,
              }}
              faultTreeId={detail.fault_tree_id}
              cfmTreeId={detail.cfms[cfmKey].fault_tree_id}
              hfeName={detail.label.name}
              onResponseUpdated={onResponseUpdated}
              updateScore={props.updateScore}
              updateProgress={getProgress}
            />
          </Grid>
        );
      })}
      <Button
        variant="contained"
        color="primary"
        onClick={toggleModal}
        style={{
          marginTop: "10px",
          backgroundColor: "#00897b",
          color: "white",
          borderRadius: "8px",
          padding: "8px 16px",
          textTransform: "none",
        }}
        startIcon={<FileCopyIcon />}
      >
        Copy CFM
      </Button>
      <Modal open={modalOpen} onClose={toggleModal}>
        <Box style={modalStyle}>
          <Typography variant="h6" gutterBottom>
            Select a CFM
          </Typography>
          <FormControl fullWidth style={{ marginBottom: "15px" }}>
            <InputLabel>Select Model</InputLabel>
            <Select value={selectedModel} onChange={handleModelChange}>
              {cfmList.map((model: any) => (
                <MenuItem key={model?.id} value={model?.id?.toString()}>
                  {model?.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "15px" }} disabled={!selectedModel}>
            <InputLabel>Select Critical Function</InputLabel>
            <Select value={selectedCFS} onChange={handleCFSChange}>
              {cfs.map((cfsItem: any, index: number) => (
                <MenuItem key={index} value={cfsItem.title}>
                  {cfsItem.title}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "15px" }} disabled={!selectedCFS}>
            <InputLabel>Select Event (BP)</InputLabel>
            <Select value={selectedBP} onChange={handleBPChange}>
              {bps.map((bp, index) => (
                <MenuItem key={index} value={bp}>
                  {bp}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth style={{ marginBottom: "15px" }} disabled={!selectedBP}>
            <InputLabel>Select CFM</InputLabel>
            <Select value={selectedCFM} onChange={handleCFMChange}>
              {cfms.map((cfm, index) => (
                <MenuItem key={index} value={cfm}>
                  {cfm}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCopyCFM}
            style={{ marginTop: "15px", float: "right" }}
            disabled={!selectedCFM}
          >
            Submit
          </Button>
        </Box>
      </Modal>
    </>
  );
};

export default SelectedPIFs;
